<template>
  <div class="agreement-main">
    <h2>协议文章</h2>
    <h3>特别提醒:</h3>
    <p>
      首次充值用户，充值成功十分钟后测试使用，再设置apn，因大部分全网通手机是不需要设置apn的（详情见设置方法）
    </p>
    <h3>停机可能原因:</h3>
    <p>1、26号前没充值<span class="font-blue">次月套餐</span>。</p>
    <p>2、主套餐使用完毕，需要充加餐包。</p>
    <p>3、新卡26号后，要使用本月+次月，必须冲一个本月可用加一个下月可用。</p>
    <h3>首次充值无法使用可能原因:</h3>
    <p>1、没有设置apn</p>
    <p>2、没有开启数据漫游</p>
    <p>3、手机不支持移动或者电信4G卡</p>
    <h3>注意:</h3>
    <p>
      一、26号前必须选择下月可用的主套餐进行预充值，否则26号到31号该卡将无法使用。
    </p>
    <p>
      二、新卡首充要选本月可用的主套餐进行充值，才可以使用。26号以后新卡如果当月就想用，需要先充值一个本月可用的主套餐，再充值一个下月可用的主套餐才可以正常使用。
    </p>
    <p>
      三、如果当月主套餐流量已经使用完毕，必须充值加油包当月才可以使用，次月的主套餐流量无法在当月使用，不充值的到次月1号才可以继续使用。
    </p>
    <p>四、下月可用的意思是，次月1号0点开始你预存的次月主套餐会到账。</p>
    <p>
      五、本月可用的意思是当月月底就会失效，例如
      28号你充值了一个本月可用的套餐，那么31号这个套餐就会过期失效。
    </p>
    <p>六、加餐包都是本月可用，只是作为主套餐流量不够使用的一个补充产品。</p>
    <p>
      七、刚充值的卡都有复机时间(30分钟左右)，如果没有及时复机，可以退出多查询几次流量就会加速复机过程，或报给卖家复机。
    </p>
    <div class="btn-wrap">
      <button type="button" class="btn">同意</button>
      <button type="button" class="btn btn-default">忽略</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'agreement',
  components: {
  },
  data () {
    return {
      headerTitle: '协议文章'
    }
  },
  mounted () {
    document.title = this.$route.meta.title
  }
}
</script>

<style lang="scss" scoped>
.agreement-main {
  padding: 1.875rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #666;
  background-color: #fff;
  h2 {
    font-weight: bold;
    font-size: 1.125rem;
    text-align: center;
    padding-bottom: 0.75rem;
    color: #333;
  }
  h3 {
    font-size: 0.875rem;
    font-weight: bold;
    margin-top: 1.125rem;
  }
  .font-blue {
    color: #0178f8;
  }
  .btn-wrap {
    margin-top: 2.5rem;
  }
}
</style>
